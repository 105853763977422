import { defineStore } from 'pinia'
import { useStore } from 'vuex'

export const usePermissionsStore = defineStore('permissionsStore', {
  state: () => ({

  }),
  getters: {
  },
  actions: {
    validarPermiso (permission) {
      const store = useStore()
      const user = store.getters['auth/user']
      if (user.admin) {
        return true
      } else {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permission)) return true
        return false
      }
    }
  }
})
